.sdk {
  .ant-collapse-item {
    li {
      &::before {
        content: '- ';
      }
    }
  }

  .ant-table-content {
    table {
      table-layout: fixed!important;
    }
  }

  

  .ant-table-wrapper {
    max-width: 600px;
    padding: 0 8px;
    overflow: hidden;
    border: 2px solid #d3d3d3;
    border-radius: 12px;
    
    th {
      font-size: 12px;
      color: #444;
      background-color: transparent;

      @media (min-width: $media-576) {
        font-size: 18px;
        font-weight: bold;
      }
    }

    th, td {
      border-bottom: 2px solid #d3d3d3;
    }

    .ant-table-row {
      &:hover {
        td {
          background-color: $white;
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }

      .ant-table-cell {
        &:last-child {
          padding: 16px 0;
        }
      }
    }

    .token {
      display: flex;
      align-items: center;
      font-size: 11px;
      padding: 5px 10px;
      border-radius: 9px;
      background-color: rgb(230, 230, 230);

      .token__row {
        flex: 1 0 85%;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .copy-icon {
        font-size: 24px;
        color: $theme-dark-main-color;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    .token-cell {
      max-width: 200px;
    }

    &.container-only {
      padding: 16px;
    }
  }

  .action-icon {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0;
    border: none;
    transform: rotate(90deg);

    &:hover,
    &:focus {
      background: $light-gray;
    }

    .anticon {
      line-height: 0;
    }

    svg {
      font-size: 35px;
      fill: $theme-dark-main-color;
    }
    
  }

  .ant-btn-link {
    padding: 0;
  }

  .ant-popover-inner-content {
    .ant-btn-link {
      font-size: 16px!important;
      font-weight: bold;

      &:hover {
        opacity: 0.2;
      }
    }
  }
}


