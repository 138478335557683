.aside {
  position: fixed;
  top: 0; 
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  z-index: -10;
  transition: opacity 0.3s ease 0s;

  &.isOpen {
    opacity: 1;
    z-index: 5;
  }

  @media (min-width: $media-768) {
    position: static;
    opacity: 1;
    width: 256px;
    z-index: 5;
  }

  nav {
    height: 100%;
  }
}

.navigation {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $theme-dark-gray-color;

  .ant-menu-sub {
    background-color: $theme-dark-gray-color;
  }

  .ant-menu-item {
    font-size: 18px;

    @media (min-width: $media-768) {
      font-size: 16px;
    }

    &::after {
      border-right-color: $turquoise;
    }

    &:hover {
      color: $turquoise;
    }

    &-selected {
      color: $turquoise;
      background-color: $theme-soft-gray-color;
      font-weight: bold;
    }
  }

  .ant-menu-submenu {
    &-title {
      font-size: 18px;

      &:hover {
        color: $turquoise;
      }

      @media (min-width: $media-768) {
        font-size: 16px;
      }
    }

    &-selected {
      color: $turquoise;
    }
  }
}