/* Colors */
$white: #fff;
$black: #444;
$light-gray: #f6f8fa;
$dark-gray: #e8e8e8;
$turquoise: #30b5b9;

$theme-dark-main-color: #16cdc8;
$theme-soft-main-color: #18e7e0;
$theme-dark-gray-color: #e6e6e6;
$theme-soft-gray-color: #f2f2f2;

/* Breakpoints */
$media-576: 576px;
$media-768: 768px;
$media-992: 992px;
$media-1240: 1240px;









