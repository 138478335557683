.mobile-menu-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;
  border-radius: 50%;
  background-color: #20232a;
  border: 1px solid rgba(255, 255, 255, 0.1);
  outline: none;
  cursor: pointer;

  @media (min-width: 768px) {
    display: none;
  }
}

.mobile-menu-btn__inner {
  display: block;
  width: 20px;
  height: 2px;
  position: relative;
  background-color: $theme-soft-main-color;
  transition: background-color .15s .15s ease;

  &:after,
  &:before {
      content: '';
      display: block;
      height: 2px;
      width: 20px;
      position: absolute;
      left: 0;
      background-color: $theme-soft-main-color;
  }

  &:after {
    bottom: -8px;
    transition: bottom .15s .15s ease, transform .15s ease;
  }

  &:before {
      top: -8px;
      transition: top .15s .15s ease, transform .15s ease;
  }
}

.mobile-menu-btn_toggled {
  .mobile-menu-btn__inner {
    background-color: transparent;

    &:after {
      bottom: 0;
      transform: rotate(45deg);
      transition: bottom .15s ease, transform .15s .15s ease;
    }

    &:before {
        top: 0;
        transform: rotate(-45deg);
        transition: top .15s ease, transform .15s .15s ease;
    }
  }
}