/* src/App.css */
.map-container {
  position: absolute;
  top: 200px;
  bottom: 0;
  left: 0;
  right: 0;
}
.legend-key-1 {
  height: 12px;
  width: 12px;
  background-color: #0DD6D6;
  border-radius: 50%;
  display: inline-block;
}
.legend-key-2 {
  height: 12px;
  width: 12px;
  background-color: #D6960D;
  border-radius: 50%;
  display: inline-block;
}
