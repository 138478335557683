.profile {
  .ant-form {
    max-width: 400px;
  }

  .ant-input {
    &[disabled] {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-form-item-required {
    &::before {
      display: none!important;
    }
  }

  button {
    padding: 4px 35px;;
  }
}
