.create-token {
  input {
    max-width: 500px;
  }

  button {
    
  }

  button:first-child {
    margin-right: 15px;
  }

  .ant-radio-wrapper {
    display: block;
    margin-bottom: 10px;
    padding-left: 10px;
    white-space: pre-wrap;
  }
}