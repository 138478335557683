.code-text {
  background-color: $light-gray;
  font-size: 85%;
}

.underline {
  text-decoration: underline;
}

/* Caption */
.caption-primary {
  font-size: 25px;
  color: $turquoise;
  font-weight: bold;
}
.caption-secondary {
  font-size: 21px;
  color: $black;
  font-weight: bold;
  margin-bottom: 7px;
}

/* Link */
.link-primary {
  color: $turquoise;
  text-decoration: none;

  &:hover {
    color: $turquoise;
    text-decoration: underline;
  }
}

 /* Button */
.button-primary {
  font-size: 13px;
  border-radius: 16px;
  border: none;
  background-color: $theme-dark-main-color;
  
  &:hover,
  &:focus {
    background-color: $theme-soft-main-color;
  }
}

.button-secondary {
  font-size: 13px;
  border-radius: 16px;
  border: 2px solid $turquoise;
  background-color: $white;
  color: $theme-dark-main-color;
  
  &:hover {
    color: $white;
    background-color: $turquoise;
    border: 2px solid $turquoise;
  }
}



/* Button */
// @mixin button {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 34px;
//   height: 60px;
//   width: 210px;
//   cursor: pointer;
//   border: 0;
//   outline: 0;
// }



