
p,
dd,
dl,
figure,
blockquote {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

img {
  display: block;
  border: none;

}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: inherit;
}
