@import '../../../components/header/styles';
@import '../../../components/sidebar/styles';
@import '../../../components/mobile-menu-button/styles';

/* Accordion */
.accordion {
  max-width: 900px;
  font-size: 16px;
  background-color: $white;
  border: 0;
  
  .ant-collapse-item {
    margin-bottom: 15px;
    border-bottom: 0;
  }

  &:last-child > .ant-collapse-header {
    border-radius: 8px!important;
  }

  .ant-collapse-header {
    font-weight: bold;
    font-size: 18px;
    border-radius: 8px!important;
    background-color: $dark-gray;
  }

  .ant-collapse-content {
    border-top: 0;
 
    .data {
      margin-bottom: 20px;
    }

    h5 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
}

/* Ant form */
.ant-form {
  .ant-form-item {
    &-label {
      padding-bottom: 0;
    }

    &-explain {
      font-size: 13px;
    }
  }

  label {
    font-size: 12px;

    @media (min-width: $media-992) {
      font-size: 14px;
    }
  }
}