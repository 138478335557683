.quick-start {

  ul {
    list-style: decimal;
    padding-left: 25px;
    margin-bottom: 15px;
  }
 
}

.before-start {
  ul {
    list-style: disc inside;
    padding-left: 10px;

    li {
      margin-bottom: 10px;
    }
  }

  .token {
    max-width: 300px;
    display: flex;
    align-items: center;
    font-size: 11px;
    padding: 5px 10px;
    border-radius: 9px;
    background-color: rgb(230, 230, 230);

    @media (min-width: $media-576) {
      max-width: 450px;
    }

    .token__row {
      flex: 1 0 85%;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .copy-icon {
      font-size: 24px;
      color: $theme-dark-main-color;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .ant-form-item-required {
    font-size: 16px!important;

    &::before {
      display: none!important;
    }
  }

  .ant-form {
    .ant-btn {
      width: 100px;
    }
  }

}


