.main-page {
  border-top: 2px solid #c1c1c1;

  &__spinner {
    min-height: calc(100vh - 64px);
    position: relative;

    .ant-spin {
      position: absolute;
      top: 40%;
      left: 50%;
    }
  }
}