.compatible-devices {
  .flex-wrap > div {
    width: calc(50% - 20px);
    margin-right: 20px;
    margin-bottom: 16px;
  }
  
  .device {
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 7px;
    padding-bottom: 3px;
  }
}